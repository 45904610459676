import Button from '@jetbrains/ring-ui/components/button/button'
import classNames from 'classnames'
import compose from 'lodash/flowRight'
import * as React from 'react'

import {withContentRect} from '../../../containers/Measure'

import type {Props} from './TruncateHtml.types'

import styles from './TruncateHtml.css'

const LINE_HEIGHT = 20

function TruncateHtml({
  className,
  children,
  moreLabel = 'Show more',
  lessLabel = 'Show less',
  clamp,
  lineHeight = LINE_HEIGHT,
  onTruncate,
  withToggleButton = true,
  measureRef,
  contentRect,
  containerRef,
  onReady,
}: Props) {
  const [showAll, setShowAll] = React.useState(false)
  const hasOverflow = (contentRect.scroll?.height ?? 0) - (contentRect.client?.height ?? 0) > 1
  React.useEffect(() => {
    if (typeof onReady === 'function') {
      onReady(hasOverflow)
    }
  }, [hasOverflow, onReady])
  const toggle = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation()
      event.preventDefault()
      setShowAll(!showAll)
      typeof onTruncate === 'function' && onTruncate(!showAll)
    },
    [onTruncate, showAll],
  )
  return (
    <div className={className} ref={containerRef}>
      <div
        ref={measureRef}
        className={classNames(styles.content, {
          [styles.hasOverflow]: hasOverflow && !showAll,
        })}
        style={{
          lineHeight: `${lineHeight}px`,
          ...(clamp != null && !showAll
            ? {
                WebkitLineClamp: clamp,
                maxHeight: clamp * lineHeight,
                minHeight: lineHeight,
              }
            : {}),
        }}
      >
        {children}
      </div>
      {withToggleButton && (hasOverflow || showAll) && (
        <Button className={styles.showButton} onClick={toggle} text>
          {showAll ? lessLabel : moreLabel}
        </Button>
      )}
    </div>
  )
}

export default compose(React.memo, withContentRect(['scroll', 'client']))(TruncateHtml)
